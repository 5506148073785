<template>
    <div class="postSlider inSalabamSlider" v-if="!loading">
        <div>
            <div class="sliderTitle">
                <span class="common-title">In|Salabam </span>&nbsp;
                <span>- In cerca di ispirazione? Ti aiutiamo noi con le nostre selezioni.</span>
                <!-- <span class="info"><SvgInfoBlue v-b-popover.hover.top="'In cerca di ispirazione? Ti aiutiamo noi con le nostre selezioni. Clicca e scopri!'" /></span> -->
            </div>
        </div>
        <div class="slick">
            <Slick
                ref="slickInSalabam"
                :options="slickOptions"
                @init="handleInit"
            >
                <div
                    v-for="(singlePost,index) in posts"
                    :key="'slide-'+index"
                    class="slideInSalabam"
                    :data-inSalabamId="singlePost.inSalabamId"
                >
                    <div class="postPad">
                        <div class="postPad__thumbnail" :style="'background-image:url('+singlePost.padImage+');'">
                            <div class="slick-credits" v-if="singlePost.credits" v-html="singlePost.credits"></div>
                        </div>

                        <div class="postPad__content">
                            <div>
                                <div class="postPad__title">{{singlePost.title}}
                                </div>
                                <div class="postPad__excerpt" v-html="singlePost.abstract"></div>
                            </div>
                            <div class="postPad__link">
                                <!--<b-button size="sm" variant="secondary" @click="inSalabam(singlePost)">Scopri di più</b-button>-->
                                <b-button size="sm" variant="secondary">Scopri di più</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </Slick>
            <span class="slick-arrow" id="slickInSalabamPrev">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="slick-arrow" id="slickInSalabamNext">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </div>
    </div>
    <div class="postSlider loading" v-else>
        <Spinner />
    </div>
</template>

<script>
  import Api from '../../api'
  import Spinner from '../atoms/spinner'
  import Slick from 'vue-slick'
  //import SvgInfoBlue from './../svg/info-blue'

  export default {
    name: 'post-slider',
    components: {
        Spinner,
        Slick,
        //SvgInfoBlue,
    },
    props:
    {
    },
    data() {
        return {
            loading: true,
            posts: [],
            slickOptions: {
                autoplay: false,//(this.$config.guiSettings.autoplaySpeedPosts != 0),
                autoplaySpeed: this.$config.guiSettings.autoplaySpeedPosts,
                //vertical: true,
                //verticalSwiping: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                prevArrow: '#slickInSalabamPrev',
                nextArrow: '#slickInSalabamNext',
                adaptiveHeight:true,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            centerMode: false,
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            centerMode: false,
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            centerMode: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                        }
                    }
                ]
            },

        }
    },
    mounted()
    {
        let _this = this
            _this.loading = true

        Api.getInSalabam({})
        .then((data) => {
            _this.posts = data.results
            _this.loading = false
        })
    },
    methods:{

    handleInit(event, slick) {
        const [ slickTrack ] = slick.$slideTrack
        slickTrack.childNodes.forEach(slickSlide => {
            let _this = this
            slickSlide.onclick = function(e){
                window.console.log(e.target)
                let inSalabamId = parseInt(e.target.closest('.slideInSalabam').getAttribute('data-inSalabamId'))
                let inSalabam = _this.posts.filter(function (post) {
                    return post.inSalabamId == inSalabamId
                })[0]
                _this.inSalabam(inSalabam)
            }
        })
    },
        inSalabam(inSalabam)
        {
            this.mixinSendMutation('setGeo',inSalabam.geo)
            this.mixinGoTo('in-salabam',{
                inSalabamId: inSalabam.inSalabamId,
                inSalabamTitle: (inSalabam.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
            })
        }
    }
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    
    .inSalabamSlider
    {
        .slick
        {
            .postPad
            {
                cursor:pointer;
                .postPad__thumbnail
                {
                    width:auto !important;
                    margin-right:0.5rem;
                    position:relative;
                }
                .postPad__content
                {
                    @include media-breakpoint-up(lg)
                    {
                        padding-bottom:0;
                        padding-right:1rem;
                    }
                }
                .postPad__link
                {
                    button
                    {
                        display:block;
                        width:100%;
                        margin-top:1rem;
                    }
                }
                .postPad__title
                {
                    margin-top:$grid-gutter-width/2;
                }
            }
        }

        .slick-arrow
        {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            width:32px;
            height:32px;
            text-align:center;
            border-radius: 50%;
            background:rgba($white,0.9);
            display:flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;
            &#slickInSalabamPrev {left:0;}
            &#slickInSalabamNext {right:0;svg{transform:rotate(180deg);}}

            @include media-breakpoint-up(md)
            {
                width:38px;
                height:38px;
            }
        }
    }

</style>