var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Calque_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 787.95 232.44",
      },
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M143.73,100.93c-6.74,0-11.03,4.39-12.36,10.21h24c-.82-6.43-5.31-10.21-11.64-10.21Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M79.59,103.49c-8.58,0-13.99,5.92-13.99,14.2s5.51,14.3,13.99,14.3,14.2-5.82,14.2-14.3-5.72-14.2-14.2-14.2Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M252.79,89.9c-12.88,0-22.92,5.15-24.55,18.5-.12,0,.08,0-.04,0C224.14,47.86,176.38,0,114.81,0,66.16,0,24.51,29.91,7.19,72.34H48.44l-2.14,13.89H13.92v14.71h29.31l-2.25,13.38H13.92v8.58c0,5.21,2.25,7.66,6.54,7.66h27.68l-2.25,13.89H15.25c-1.49,0-2.91-.14-4.26-.38h0s-5.31-.57-8.59-4.84c-.08-.1-1.42-1.69-2.08-4.3-.17-.68-.18-.73-.32-1.26,.22,1.73,.59,4.05,.75,4.91,.93,4.84,2.12,8.95,2.22,9.24,13.76,48.83,58.61,84.63,111.84,84.63s100.45-37.45,113.06-88h-15.62v-28.39c0-7.66-2.96-12.56-10.52-12.56s-10.52,4.9-10.52,12.56v28.39h-15.63v-32.27c0-15.42,11.23-22.26,26.15-22.26s26.15,6.84,26.15,22.26v32.27s15.71,0,15.71,0v-28.39c0-7.66,2.04-12.56,9.5-12.56,2.35,0,5.21,.71,6.23,1.23l2.96-13.69c-1.73-.2-6.13-1.12-9.5-1.12Zm-143.27,24.61c0,19.51-11.54,30.95-30.64,30.95-17.67,0-29.62-11.85-29.62-27.78s11.34-27.78,28.29-27.78c7.76,0,13.48,3.06,16.34,6.13v-23.69h15.63v42.18Zm39.42,19.2c4.6,0,8.85-1.33,12.84-3.17l-1.3,12.36c-4.49,1.84-9.91,2.55-15.32,2.55-17.98,0-30.64-10.52-30.64-28.39,0-15.52,11.34-27.17,28.7-27.17,19.61,0,29.41,12.46,29,31.66h-40.44c1.53,8.78,9.91,12.15,17.16,12.15Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M288.23,89.9c-17.36,0-28.7,11.64-28.7,27.17,0,17.87,12.66,28.39,30.64,28.39,5.41,0,10.83-.71,15.32-2.55l1.3-12.36c-3.98,1.84-8.24,3.17-12.84,3.17-7.25,0-15.63-3.37-17.16-12.15h40.45c.41-19.2-9.4-31.66-29.01-31.66Zm-11.85,21.24c1.33-5.82,5.62-10.21,12.36-10.21,6.33,0,10.83,3.78,11.64,10.21h-24Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M363.6,72.34v23.69c-2.86-3.06-8.58-6.13-16.34-6.13-16.95,0-28.29,11.13-28.29,27.78s11.95,27.78,29.62,27.78c19.1,0,30.64-11.44,30.64-30.95v-42.18h-15.63Zm-14.3,59.64c-8.48,0-13.99-6.13-13.99-14.3s5.41-14.2,13.99-14.2,14.2,5.72,14.2,14.2-5.72,14.3-14.2,14.3Z",
          },
        }),
      ]),
      _c("g", [
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M426.34,124.12c5.38,2.94,10.67,4.54,16.21,4.54,6.22,0,10.58-2.35,10.58-8.32,0-5.21-4.54-7.9-9.74-10.33l-6.97-3.28c-7.98-3.7-13.61-7.73-13.61-15.71,0-10.67,10-15.29,21.08-15.29,6.22,0,12.52,1.34,18.56,4.2l-3.53,8.32c-4.7-3.11-9.74-4.2-14.53-4.2-5.38,0-10.67,1.34-10.67,6.8,0,4.45,5.04,6.38,9.58,8.48l6.89,3.28c8.74,4.12,13.94,8.57,13.94,17.56,0,11.42-9.74,16.88-21.5,16.88-6.72,0-13.27-1.26-19.99-4.45l3.7-8.48Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M480.1,76.75v20.08c3.28-4.28,8.32-5.63,12.85-5.63,11.09,0,17.56,6.05,17.56,17.98v26.88h-9.32v-25.7c0-6.72-2.77-11.34-10-11.34-6.3,0-11.09,3.61-11.09,10.92v26.12h-9.32v-59.3h9.32Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M540.41,91.19c13.52,.08,23.94,8.4,24.02,22.85,.08,14.45-10.42,22.76-24.02,22.76s-24.11-8.32-24.11-22.76,11.17-22.93,24.11-22.85Zm0,37.46c8.23,0,14.28-5.63,14.2-14.62,0-8.99-6.05-14.7-14.2-14.7-7.81-.08-14.36,5.63-14.36,14.7s6.13,14.62,14.36,14.62Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M569.64,113.96c0-13.27,9.16-22.76,23.94-22.76,13.78,0,23.94,9.41,23.94,22.93s-9.41,22.68-22.18,22.68c-7.73,0-13.36-3.28-16.38-7.39v21.84h-9.32v-37.3Zm23.94,14.7c8.82,0,14.2-6.3,14.2-14.62s-5.54-14.7-14.2-14.7-14.45,6.05-14.45,14.7,5.8,14.62,14.45,14.62Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M622.73,113.96c0-13.27,9.16-22.76,23.94-22.76,13.78,0,23.94,9.41,23.94,22.93s-9.41,22.68-22.18,22.68c-7.73,0-13.36-3.28-16.38-7.39v21.84h-9.32v-37.3Zm23.94,14.7c8.82,0,14.2-6.3,14.2-14.62s-5.54-14.7-14.2-14.7-14.45,6.05-14.45,14.7,5.8,14.62,14.45,14.62Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M686.15,76.75v8.06h-9.41v-8.06h9.41Zm0,15.04v44.27h-9.41v-44.27h9.41Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M724.62,110.68c0-7.14-3.53-11.34-10.58-11.34s-10.5,4.2-10.5,11.34v25.37h-9.32v-26.88c0-12.01,8.06-17.89,19.82-17.89s19.91,5.88,19.91,17.89v26.88h-9.32v-25.37Z",
          },
        }),
        _c("path", {
          staticClass: "edenred_shoppin_logo",
          attrs: {
            d: "M787.95,126.05c0,9.58-.84,15.88-9.58,25.45h-11.59c6.47-5.29,11.34-11.42,12.1-22.68-3.44,5.12-9.83,7.9-16.72,7.9-12.77,0-22.43-8.99-22.43-22.6s10.75-22.93,23.77-22.93c14.78,0,24.44,8.99,24.44,23.77v11.09Zm-24.28-26.71c-8.57,0-14.28,6.55-14.28,14.7s5.46,14.62,14.28,14.62,14.45-5.96,14.45-14.62-5.8-14.7-14.45-14.7Z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }