var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "xmlns:dc": "http://purl.org/dc/elements/1.1/",
        "xmlns:cc": "http://creativecommons.org/ns#",
        "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
        "xmlns:svg": "http://www.w3.org/2000/svg",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
        "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
        version: "1.1",
        id: "svg2",
        "xml:space": "preserve",
        width: "259.77307",
        height: "51.447998",
        viewBox: "0 0 259.77307 51.447998",
        "sodipodi:docname": "vivamod_payoff.svg",
        "inkscape:version": "1.0.2 (394de47547, 2021-03-26)",
      },
    },
    [
      _c(
        "metadata",
        { attrs: { id: "metadata8" } },
        [
          _c(
            "rdf:RDF",
            [
              _c(
                "cc:Work",
                { attrs: { "rdf:about": "" } },
                [
                  _c("dc:format", [_vm._v("image/svg+xml")]),
                  _c("dc:type", {
                    attrs: {
                      "rdf:resource": "http://purl.org/dc/dcmitype/StillImage",
                    },
                  }),
                  _c("dc:title"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "defs",
        { attrs: { id: "defs6" } },
        [
          _c(
            "linearGradient",
            {
              attrs: {
                x1: "0",
                y1: "0",
                x2: "1",
                y2: "0",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "matrix(42.326008,0,0,-42.326008,320.017,297.48465)",
                spreadMethod: "pad",
                id: "linearGradient30",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-opacity": "1", "stop-color": "#f39453" },
                attrs: { offset: "0", id: "stop24" },
              }),
              _c("stop", {
                staticStyle: { "stop-opacity": "1", "stop-color": "#f39453" },
                attrs: { offset: "0.00128142", id: "stop26" },
              }),
              _c("stop", {
                staticStyle: { "stop-opacity": "1", "stop-color": "#eb5656" },
                attrs: { offset: "1", id: "stop28" },
              }),
            ],
            1
          ),
          _c(
            "clipPath",
            { attrs: { clipPathUnits: "userSpaceOnUse", id: "clipPath40" } },
            [
              _c("path", {
                attrs: { d: "M 0,595.276 H 841.89 V 0 H 0 Z", id: "path38" },
              }),
            ]
          ),
        ],
        1
      ),
      _c("sodipodi:namedview", {
        attrs: {
          pagecolor: "#ffffff",
          bordercolor: "#666666",
          borderopacity: "1",
          objecttolerance: "10",
          gridtolerance: "10",
          guidetolerance: "10",
          "inkscape:pageopacity": "0",
          "inkscape:pageshadow": "2",
          "inkscape:window-width": "1920",
          "inkscape:window-height": "1025",
          id: "namedview4",
          showgrid: "false",
          "fit-margin-top": "0",
          "fit-margin-left": "0",
          "fit-margin-right": "0",
          "fit-margin-bottom": "0",
          "inkscape:zoom": "1.1062095",
          "inkscape:cx": "-75.606494",
          "inkscape:cy": "26.89067",
          "inkscape:window-x": "1920",
          "inkscape:window-y": "27",
          "inkscape:window-maximized": "1",
          "inkscape:current-layer": "g10",
        },
      }),
      _c(
        "g",
        {
          attrs: {
            id: "g10",
            "inkscape:groupmode": "layer",
            "inkscape:label": "Logo_Vivamod_Def",
            transform: "matrix(1.3333333,0,0,-1.3333333,-426.68932,423.74132)",
          },
        },
        [
          _c("g", { attrs: { id: "g12" } }, [
            _c("g", { attrs: { id: "g14" } }, [
              _c("g", { attrs: { id: "g20" } }, [
                _c("g", { attrs: { id: "g22" } }, [
                  _c("path", {
                    staticStyle: {
                      fill: "url(#linearGradient30)",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 352.078,313.983 c -0.105,-0.106 -0.198,-0.223 -0.276,-0.35 v 0 l -9.842,-15.997 c -0.745,-0.666 -1.424,-0.67 -2.166,-0.016 v 0 l -9.209,15.966 c -0.082,0.146 -0.185,0.278 -0.303,0.397 v 0 c -1.723,1.724 -4.302,2.236 -6.572,1.309 v 0 c -2.259,-0.926 -3.674,-3.005 -3.693,-5.427 v 0 -0.096 -14.667 c 0,-8.757 7.124,-15.882 15.881,-15.882 v 0 c 1.055,0 1.91,0.856 1.91,1.911 v 0 c 0,1.055 -0.855,1.91 -1.91,1.91 v 0 c -6.65,0 -12.061,5.411 -12.061,12.061 v 0 14.733 c 0.01,1.285 1.014,1.796 1.321,1.922 v 0 c 0.306,0.125 1.335,0.452 2.262,-0.328 v 0 l 9.204,-15.958 c 0.083,-0.146 0.186,-0.278 0.304,-0.397 v 0 c 2.355,-2.356 5.524,-2.358 7.88,0 v 0 c 0.105,0.106 0.198,0.223 0.275,0.35 v 0 l 9.839,15.989 c 0.931,0.799 1.972,0.469 2.28,0.344 v 0 c 0.306,-0.126 1.311,-0.637 1.321,-1.988 v 0 -14.667 c 0,-6.65 -5.41,-12.061 -12.061,-12.061 v 0 c -1.055,0 -1.91,-0.855 -1.91,-1.91 v 0 c 0,-1.055 0.855,-1.911 1.91,-1.911 v 0 c 8.756,0 15.882,7.125 15.882,15.882 v 0 14.667 0.097 c -0.021,2.422 -1.438,4.501 -3.696,5.426 v 0 c -0.75,0.308 -1.533,0.457 -2.309,0.457 v 0 c -1.569,0 -3.106,-0.612 -4.261,-1.766",
                      id: "path32",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("g", { attrs: { id: "g34" } }, [
            _c("g", { attrs: { id: "g36", "clip-path": "url(#clipPath40)" } }, [
              _c(
                "g",
                {
                  attrs: {
                    id: "g42",
                    transform: "translate(379.3462,291.4006)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 -6.962,18.564 h 5.38 l 4.148,-13.36 4.219,13.36 h 5.204 L 5.027,0 Z",
                      id: "path44",
                    },
                  }),
                ]
              ),
              _c("path", {
                staticStyle: {
                  fill: "#39424f",
                  "fill-opacity": "1",
                  "fill-rule": "nonzero",
                  stroke: "none",
                },
                attrs: {
                  d: "m 393.691,309.965 h 4.923 v -18.564 h -4.923 z m 5.274,5.028 c 0,-0.54 -0.123,-1.019 -0.369,-1.441 -0.246,-0.422 -0.586,-0.756 -1.019,-1.002 -0.435,-0.246 -0.91,-0.369 -1.424,-0.369 -0.517,0 -0.991,0.123 -1.424,0.369 -0.435,0.246 -0.774,0.58 -1.021,1.002 -0.246,0.422 -0.369,0.901 -0.369,1.441 0,0.539 0.123,1.02 0.369,1.442 0.247,0.422 0.586,0.756 1.021,1.002 0.433,0.246 0.907,0.369 1.424,0.369 0.514,0 0.989,-0.123 1.424,-0.369 0.433,-0.246 0.773,-0.58 1.019,-1.002 0.246,-0.422 0.369,-0.903 0.369,-1.442",
                  id: "path46",
                },
              }),
              _c(
                "g",
                {
                  attrs: {
                    id: "g48",
                    transform: "translate(407.9301,291.4006)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 -6.961,18.564 h 5.379 l 4.148,-13.36 4.22,13.36 H 11.99 L 5.028,0 Z",
                      id: "path50",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g52",
                    transform: "translate(425.6157,296.9211)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 c 0,-0.656 0.27,-1.195 0.809,-1.617 0.539,-0.422 1.254,-0.633 2.144,-0.633 0.774,0 1.465,0.163 2.074,0.492 0.61,0.328 1.091,0.779 1.443,1.354 0.351,0.574 0.527,1.213 0.527,1.916 V 1.687 C 6.48,1.898 5.93,2.068 5.344,2.197 4.758,2.326 4.172,2.391 3.586,2.391 2.484,2.391 1.611,2.174 0.967,1.74 0.322,1.307 0,0.727 0,0 m 1.758,-6.048 c -0.961,0 -1.84,0.141 -2.637,0.422 -0.797,0.281 -1.494,0.686 -2.092,1.213 -0.597,0.527 -1.06,1.142 -1.388,1.847 -0.328,0.703 -0.493,1.476 -0.493,2.32 0,1.172 0.317,2.203 0.95,3.094 0.632,0.89 1.523,1.588 2.672,2.091 1.148,0.504 2.484,0.756 4.007,0.756 0.774,0 1.506,-0.07 2.198,-0.211 C 5.666,5.344 6.34,5.145 6.997,4.887 v 1.09 C 6.997,7.032 6.698,7.888 6.101,8.544 5.502,9.199 4.687,9.528 3.656,9.528 2.883,9.528 2.18,9.335 1.547,8.948 0.914,8.562 0.469,8.017 0.211,7.313 l -4.395,0.879 c 0.258,1.078 0.768,2.022 1.53,2.83 0.761,0.809 1.693,1.436 2.795,1.881 1.101,0.446 2.297,0.668 3.586,0.668 1.195,0 2.286,-0.187 3.27,-0.562 0.984,-0.375 1.833,-0.914 2.549,-1.617 0.715,-0.704 1.267,-1.53 1.653,-2.479 0.387,-0.949 0.58,-2.011 0.58,-3.183 V -5.521 H 7.384 l -0.141,2.18 C 6.751,-4.138 6.035,-4.788 5.098,-5.292 4.16,-5.796 3.047,-6.048 1.758,-6.048",
                      id: "path54",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g56",
                    transform: "translate(441.3325,291.4006)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 v 18.564 h 4.535 l 0.141,-2.355 c 0.539,0.891 1.266,1.594 2.18,2.109 0.915,0.516 1.946,0.774 3.095,0.774 1.241,0 2.35,-0.305 3.322,-0.914 0.973,-0.61 1.729,-1.453 2.268,-2.532 0.563,1.032 1.377,1.864 2.443,2.497 1.067,0.632 2.291,0.949 3.674,0.949 1.383,0 2.578,-0.322 3.587,-0.967 1.007,-0.645 1.787,-1.564 2.338,-2.76 0.551,-1.195 0.827,-2.601 0.827,-4.219 V 0 h -4.923 v 10.513 c 0,1.289 -0.282,2.303 -0.844,3.042 -0.563,0.738 -1.348,1.107 -2.356,1.107 -0.703,0 -1.33,-0.171 -1.881,-0.51 -0.551,-0.339 -0.978,-0.814 -1.283,-1.423 -0.305,-0.611 -0.457,-1.302 -0.457,-2.076 V 0 h -4.922 v 10.513 c 0,1.289 -0.281,2.303 -0.844,3.042 -0.562,0.738 -1.348,1.107 -2.356,1.107 -0.703,0 -1.331,-0.171 -1.881,-0.51 C 6.111,13.813 5.684,13.338 5.38,12.729 5.074,12.118 4.922,11.427 4.922,10.653 V 0 Z",
                      id: "path58",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g60",
                    transform: "translate(482.6801,295.3039)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 C 0.891,0 1.687,0.233 2.391,0.703 3.094,1.172 3.65,1.811 4.061,2.619 4.471,3.428 4.676,4.348 4.676,5.379 4.676,6.41 4.471,7.33 4.061,8.14 3.65,8.948 3.094,9.586 2.391,10.056 1.687,10.524 0.891,10.759 0,10.759 c -0.891,0 -1.687,-0.235 -2.391,-0.703 C -3.094,9.586 -3.65,8.948 -4.061,8.14 -4.471,7.33 -4.676,6.41 -4.676,5.379 c 0,-1.031 0.205,-1.951 0.615,-2.76 C -3.65,1.811 -3.094,1.172 -2.391,0.703 -1.687,0.233 -0.891,0 0,0 m -0.07,-4.431 c -1.383,0 -2.66,0.246 -3.832,0.739 -1.172,0.492 -2.193,1.177 -3.059,2.056 -0.868,0.88 -1.542,1.917 -2.022,3.113 -0.481,1.195 -0.721,2.496 -0.721,3.902 0,1.406 0.246,2.701 0.738,3.886 0.493,1.182 1.179,2.22 2.058,3.111 0.879,0.891 1.91,1.582 3.094,2.074 1.183,0.492 2.478,0.738 3.884,0.738 1.383,0 2.66,-0.246 3.832,-0.738 1.172,-0.492 2.192,-1.178 3.06,-2.056 C 7.828,11.515 8.503,10.482 8.983,9.3 9.464,8.115 9.704,6.82 9.704,5.414 9.704,4.008 9.458,2.707 8.966,1.512 8.474,0.316 7.788,-0.728 6.909,-1.618 6.03,-2.509 4.998,-3.2 3.814,-3.692 2.631,-4.185 1.336,-4.431 -0.07,-4.431",
                      id: "path62",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g64",
                    transform: "translate(505.3178,295.3039)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 c 0.891,0 1.688,0.233 2.392,0.703 0.703,0.469 1.259,1.108 1.67,1.916 0.41,0.809 0.615,1.729 0.615,2.76 C 4.677,6.41 4.472,7.33 4.062,8.14 3.651,8.948 3.095,9.586 2.392,10.056 1.688,10.524 0.891,10.759 0,10.759 c -0.891,0 -1.687,-0.235 -2.391,-0.703 C -3.094,9.586 -3.65,8.948 -4.061,8.14 -4.471,7.33 -4.676,6.41 -4.676,5.379 c 0,-1.031 0.205,-1.951 0.615,-2.76 C -3.65,1.811 -3.094,1.172 -2.391,0.703 -1.687,0.233 -0.891,0 0,0 m -1.195,-4.431 c -1.196,0 -2.309,0.246 -3.34,0.739 -1.031,0.492 -1.934,1.177 -2.707,2.056 -0.774,0.88 -1.377,1.905 -1.811,3.077 -0.433,1.172 -0.65,2.45 -0.65,3.832 0,1.407 0.223,2.708 0.668,3.904 0.445,1.195 1.066,2.244 1.863,3.146 0.797,0.903 1.729,1.606 2.795,2.11 1.066,0.504 2.221,0.755 3.463,0.755 1.148,0 2.198,-0.228 3.147,-0.685 0.95,-0.457 1.741,-1.096 2.373,-1.916 v 9.739 H 9.529 V -3.903 H 4.993 l -0.14,2.708 C 4.196,-2.204 3.346,-2.995 2.304,-3.569 1.26,-4.144 0.094,-4.431 -1.195,-4.431",
                      id: "path66",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g68",
                    transform: "translate(373.0678,279.7824)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 V 4.645 H 0.648 L 0.685,3.933 c 0.131,0.258 0.323,0.463 0.575,0.616 0.252,0.154 0.54,0.23 0.864,0.23 0.348,0 0.651,-0.09 0.909,-0.27 C 3.291,4.329 3.483,4.077 3.609,3.753 3.688,3.957 3.804,4.136 3.96,4.289 4.116,4.441 4.301,4.562 4.514,4.648 4.727,4.735 4.956,4.779 5.202,4.779 5.55,4.779 5.852,4.696 6.106,4.531 6.361,4.366 6.557,4.134 6.691,3.834 6.826,3.534 6.895,3.174 6.895,2.754 V 0 H 6.174 V 2.709 C 6.174,3.165 6.081,3.515 5.896,3.758 5.709,4 5.439,4.122 5.085,4.122 4.839,4.122 4.62,4.063 4.428,3.946 4.236,3.829 4.085,3.671 3.974,3.47 3.862,3.269 3.807,3.042 3.807,2.79 V 0 H 3.087 V 2.709 C 3.087,3.165 2.991,3.515 2.799,3.758 2.607,4 2.334,4.122 1.98,4.122 1.74,4.122 1.524,4.063 1.332,3.946 1.14,3.829 0.99,3.671 0.882,3.47 0.774,3.269 0.72,3.042 0.72,2.79 V 0 Z",
                      id: "path70",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g72",
                    transform: "translate(390.2758,280.3049)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 C 0.228,0 0.437,0.045 0.625,0.135 0.814,0.225 0.979,0.349 1.12,0.508 1.261,0.667 1.371,0.855 1.449,1.075 1.526,1.294 1.565,1.535 1.565,1.8 1.565,2.063 1.526,2.305 1.449,2.524 1.371,2.743 1.261,2.932 1.12,3.091 0.979,3.25 0.814,3.375 0.625,3.465 0.437,3.555 0.228,3.6 0,3.6 -0.229,3.6 -0.437,3.555 -0.626,3.465 -0.814,3.375 -0.979,3.25 -1.121,3.091 -1.262,2.932 -1.371,2.743 -1.449,2.524 -1.527,2.305 -1.566,2.063 -1.566,1.8 c 0,-0.265 0.039,-0.506 0.117,-0.725 0.078,-0.22 0.187,-0.408 0.328,-0.567 C -0.979,0.349 -0.814,0.225 -0.626,0.135 -0.437,0.045 -0.229,0 0,0 m -0.009,-0.657 c -0.33,0 -0.635,0.061 -0.914,0.184 -0.279,0.123 -0.521,0.294 -0.728,0.513 -0.207,0.219 -0.369,0.479 -0.482,0.778 -0.114,0.3 -0.171,0.627 -0.171,0.982 0,0.353 0.058,0.68 0.175,0.98 0.117,0.3 0.279,0.56 0.486,0.78 0.207,0.218 0.452,0.389 0.734,0.512 0.282,0.122 0.588,0.185 0.918,0.185 0.33,0 0.635,-0.063 0.913,-0.185 C 1.201,3.949 1.444,3.778 1.651,3.56 1.858,3.34 2.019,3.08 2.133,2.78 2.247,2.48 2.304,2.153 2.304,1.8 2.304,1.445 2.245,1.118 2.128,0.818 2.012,0.519 1.85,0.259 1.643,0.04 1.436,-0.179 1.19,-0.35 0.909,-0.473 0.627,-0.596 0.32,-0.657 -0.009,-0.657",
                      id: "path74",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g76",
                    transform: "translate(402.7407,280.3049)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 C 0.228,0 0.437,0.045 0.625,0.135 0.814,0.225 0.979,0.349 1.12,0.508 1.261,0.667 1.371,0.855 1.449,1.075 1.526,1.294 1.565,1.535 1.565,1.8 1.565,2.063 1.526,2.305 1.449,2.524 1.371,2.743 1.261,2.932 1.12,3.091 0.979,3.25 0.814,3.375 0.625,3.465 0.437,3.555 0.228,3.6 0,3.6 -0.229,3.6 -0.437,3.555 -0.626,3.465 -0.814,3.375 -0.979,3.25 -1.121,3.091 -1.262,2.932 -1.371,2.743 -1.449,2.524 -1.527,2.305 -1.566,2.063 -1.566,1.8 c 0,-0.265 0.039,-0.506 0.117,-0.725 0.078,-0.22 0.187,-0.408 0.328,-0.567 C -0.979,0.349 -0.814,0.225 -0.626,0.135 -0.437,0.045 -0.229,0 0,0 m -0.126,-0.657 c -0.307,0 -0.591,0.061 -0.855,0.184 -0.264,0.123 -0.496,0.295 -0.693,0.518 -0.198,0.222 -0.352,0.481 -0.464,0.778 -0.111,0.297 -0.166,0.619 -0.166,0.968 0,0.348 0.055,0.672 0.166,0.972 0.112,0.3 0.267,0.56 0.468,0.783 0.201,0.222 0.437,0.395 0.707,0.521 0.27,0.126 0.561,0.19 0.873,0.19 0.354,0 0.673,-0.081 0.958,-0.243 0.285,-0.162 0.512,-0.39 0.68,-0.685 v 2.827 h 0.72 V -0.522 H 1.62 L 1.584,0.359 C 1.416,0.041 1.184,-0.207 0.887,-0.388 0.589,-0.567 0.252,-0.657 -0.126,-0.657",
                      id: "path78",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g80",
                    transform: "translate(415.2778,283.9406)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 C -0.246,0 -0.47,-0.061 -0.671,-0.181 -0.872,-0.301 -1.04,-0.467 -1.175,-0.68 -1.31,-0.893 -1.398,-1.141 -1.44,-1.422 H 1.377 C 1.353,-1.141 1.278,-0.893 1.152,-0.68 1.025,-0.467 0.864,-0.301 0.666,-0.181 0.468,-0.061 0.246,0 0,0 m 0.117,-4.293 c -0.343,0 -0.656,0.06 -0.94,0.18 -0.285,0.12 -0.533,0.289 -0.743,0.509 -0.21,0.218 -0.372,0.476 -0.486,0.773 -0.114,0.297 -0.171,0.626 -0.171,0.986 0,0.354 0.055,0.679 0.166,0.976 0.112,0.298 0.267,0.557 0.468,0.779 0.201,0.222 0.437,0.395 0.707,0.518 0.27,0.123 0.564,0.183 0.882,0.183 0.306,0 0.59,-0.056 0.851,-0.17 C 1.111,0.326 1.338,0.168 1.53,-0.036 1.722,-0.24 1.87,-0.483 1.976,-0.766 2.08,-1.047 2.133,-1.354 2.133,-1.684 V -1.98 h -3.61 c 0.025,-0.336 0.104,-0.63 0.24,-0.882 0.134,-0.252 0.316,-0.448 0.544,-0.585 0.228,-0.138 0.492,-0.207 0.792,-0.207 0.294,0 0.55,0.061 0.769,0.184 0.219,0.123 0.406,0.305 0.563,0.545 L 2.043,-3.204 C 1.923,-3.433 1.767,-3.627 1.575,-3.789 1.383,-3.951 1.162,-4.076 0.913,-4.163 0.664,-4.25 0.398,-4.293 0.117,-4.293",
                      id: "path82",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g84",
                    transform: "translate(425.4565,279.7824)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 V 4.645 H 0.648 L 0.693,3.78 C 0.807,4.068 0.978,4.295 1.206,4.459 1.434,4.625 1.701,4.707 2.007,4.707 2.109,4.707 2.2,4.699 2.281,4.685 2.362,4.67 2.433,4.65 2.493,4.626 L 2.403,3.942 C 2.337,3.966 2.269,3.982 2.196,3.991 2.124,4 2.052,4.005 1.98,4.005 1.729,4.005 1.508,3.942 1.318,3.816 1.13,3.69 0.982,3.512 0.878,3.28 0.772,3.05 0.72,2.778 0.72,2.466 V 0 Z",
                      id: "path86",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g88",
                    transform: "translate(435.851,279.7824)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 V 4.645 H 0.648 L 0.685,3.906 C 0.822,4.17 1.021,4.381 1.282,4.541 1.544,4.699 1.845,4.779 2.188,4.779 2.541,4.779 2.85,4.695 3.114,4.527 3.378,4.359 3.581,4.121 3.722,3.812 3.862,3.502 3.934,3.138 3.934,2.718 V 0 H 3.213 V 2.673 C 3.213,3.135 3.112,3.492 2.911,3.744 2.711,3.996 2.424,4.122 2.052,4.122 1.794,4.122 1.564,4.063 1.363,3.946 1.162,3.829 1.005,3.668 0.892,3.461 0.777,3.254 0.72,3.018 0.72,2.754 V 0 Z",
                      id: "path90",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g92",
                    transform: "translate(458.7563,279.6477)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 c -0.282,0 -0.525,0.057 -0.729,0.171 -0.205,0.114 -0.362,0.282 -0.473,0.504 -0.11,0.221 -0.166,0.492 -0.166,0.809 v 2.683 h -0.856 v 0.612 h 0.856 v 1.557 h 0.72 V 4.779 H 0.81 V 4.167 H -0.648 V 1.521 c 0,-0.276 0.058,-0.491 0.175,-0.644 0.118,-0.152 0.296,-0.23 0.535,-0.23 0.126,0 0.254,0.02 0.383,0.059 0.129,0.039 0.244,0.092 0.347,0.157 L 1.008,0.306 C 0.882,0.216 0.734,0.143 0.566,0.085 0.398,0.028 0.21,0 0,0",
                      id: "path94",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g96",
                    transform: "translate(467.7114,279.7824)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 V 4.645 H 0.648 L 0.693,3.78 C 0.807,4.068 0.978,4.295 1.206,4.459 1.434,4.625 1.701,4.707 2.007,4.707 2.109,4.707 2.2,4.699 2.281,4.685 2.362,4.67 2.433,4.65 2.493,4.626 L 2.403,3.942 C 2.337,3.966 2.269,3.982 2.196,3.991 2.124,4 2.052,4.005 1.98,4.005 1.729,4.005 1.508,3.942 1.318,3.816 1.13,3.69 0.982,3.512 0.878,3.28 0.772,3.05 0.72,2.778 0.72,2.466 V 0 Z",
                      id: "path98",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g100",
                    transform: "translate(478.4839,281.1145)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 c 0,-0.174 0.045,-0.325 0.135,-0.454 0.091,-0.13 0.217,-0.23 0.379,-0.302 0.161,-0.072 0.347,-0.108 0.557,-0.108 0.258,0 0.491,0.058 0.698,0.176 0.207,0.117 0.37,0.275 0.49,0.476 0.12,0.201 0.18,0.431 0.18,0.689 V 0.648 C 2.241,0.726 2.042,0.787 1.841,0.832 1.64,0.878 1.449,0.9 1.27,0.9 0.873,0.9 0.562,0.819 0.338,0.657 0.112,0.495 0,0.275 0,0 m 0.937,-1.467 c -0.325,0 -0.613,0.062 -0.865,0.185 -0.252,0.123 -0.448,0.292 -0.59,0.508 -0.14,0.216 -0.211,0.464 -0.211,0.747 0,0.306 0.08,0.57 0.239,0.792 0.159,0.222 0.383,0.394 0.671,0.517 0.287,0.123 0.623,0.185 1.007,0.185 0.216,0 0.435,-0.024 0.657,-0.072 C 2.067,1.347 2.265,1.284 2.439,1.206 V 1.548 C 2.439,1.8 2.39,2.021 2.291,2.209 2.191,2.398 2.055,2.546 1.881,2.65 1.707,2.756 1.503,2.809 1.27,2.809 0.987,2.809 0.743,2.742 0.536,2.61 0.329,2.478 0.174,2.292 0.072,2.052 l -0.63,0.216 c 0.084,0.24 0.214,0.447 0.392,0.621 0.177,0.174 0.39,0.308 0.639,0.405 C 0.722,3.39 0.99,3.438 1.278,3.438 1.65,3.438 1.976,3.358 2.255,3.199 2.534,3.041 2.75,2.818 2.902,2.533 3.056,2.248 3.132,1.914 3.132,1.53 v -2.862 h -0.63 l -0.045,0.72 C 2.325,-0.87 2.126,-1.077 1.858,-1.233 1.592,-1.389 1.284,-1.467 0.937,-1.467",
                      id: "path102",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g104",
                    transform: "translate(491.0669,279.7824)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "m 0,0 -1.818,4.645 h 0.792 L 0.387,0.774 1.8,4.645 H 2.564 L 0.746,0 Z",
                      id: "path106",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "g108",
                    transform: "translate(503.3247,283.9406)",
                  },
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "#39424f",
                      "fill-opacity": "1",
                      "fill-rule": "nonzero",
                      stroke: "none",
                    },
                    attrs: {
                      d: "M 0,0 C -0.246,0 -0.47,-0.061 -0.671,-0.181 -0.872,-0.301 -1.04,-0.467 -1.175,-0.68 -1.31,-0.893 -1.398,-1.141 -1.44,-1.422 H 1.377 C 1.353,-1.141 1.278,-0.893 1.152,-0.68 1.025,-0.467 0.864,-0.301 0.666,-0.181 0.468,-0.061 0.246,0 0,0 m 0.117,-4.293 c -0.343,0 -0.656,0.06 -0.94,0.18 -0.285,0.12 -0.533,0.289 -0.743,0.509 -0.21,0.218 -0.372,0.476 -0.486,0.773 -0.114,0.297 -0.171,0.626 -0.171,0.986 0,0.354 0.055,0.679 0.166,0.976 0.112,0.298 0.267,0.557 0.468,0.779 0.201,0.222 0.437,0.395 0.707,0.518 0.27,0.123 0.564,0.183 0.882,0.183 0.306,0 0.59,-0.056 0.851,-0.17 C 1.111,0.326 1.338,0.168 1.53,-0.036 1.722,-0.24 1.87,-0.483 1.976,-0.766 2.08,-1.047 2.133,-1.354 2.133,-1.684 V -1.98 h -3.61 c 0.025,-0.336 0.104,-0.63 0.24,-0.882 0.134,-0.252 0.316,-0.448 0.544,-0.585 0.228,-0.138 0.492,-0.207 0.792,-0.207 0.294,0 0.55,0.061 0.769,0.184 0.219,0.123 0.406,0.305 0.563,0.545 L 2.043,-3.204 C 1.923,-3.433 1.767,-3.627 1.575,-3.789 1.383,-3.951 1.162,-4.076 0.913,-4.163 0.664,-4.25 0.398,-4.293 0.117,-4.293",
                      id: "path110",
                    },
                  }),
                ]
              ),
              _c("path", {
                staticStyle: {
                  fill: "#39424f",
                  "fill-opacity": "1",
                  "fill-rule": "nonzero",
                  stroke: "none",
                },
                attrs: {
                  d: "m 513.503,286.461 h 0.72 v -6.679 h -0.72 z",
                  id: "path112",
                },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }