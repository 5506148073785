var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "11",
        height: "13",
        viewBox: "0 0 11 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.36694 0.0529785C4.01491 0.0548304 2.71885 0.592794 1.76282 1.54883C0.806783 2.50486 0.26882 3.80098 0.266968 5.15302C0.266968 7.96802 3.23694 11.166 5.36694 12.999C7.49894 11.166 10.467 7.96802 10.467 5.15302C10.4652 3.80098 9.92723 2.50486 8.97119 1.54883C8.01516 0.592794 6.71898 0.0548304 5.36694 0.0529785ZM5.36694 6.41998C4.99116 6.41998 4.62385 6.30857 4.3114 6.09979C3.99895 5.89102 3.75538 5.59425 3.61157 5.24707C3.46777 4.89989 3.43023 4.51792 3.50354 4.14935C3.57685 3.78079 3.75772 3.44223 4.02344 3.17651C4.28916 2.91079 4.62777 2.72981 4.99634 2.65649C5.3649 2.58318 5.74694 2.62084 6.09412 2.76465C6.4413 2.90845 6.738 3.15196 6.94678 3.46442C7.15555 3.77687 7.26697 4.14424 7.26697 4.52002C7.26697 5.02393 7.06677 5.50721 6.71045 5.86353C6.35413 6.21984 5.87085 6.41998 5.36694 6.41998Z",
          fill: "#0071BC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }