var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "54",
        height: "54",
        viewBox: "0 0 54 54",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M45.885 8.69001C42.1929 4.99801 37.4888 2.48372 32.3677 1.46516C27.2467 0.446597 21.9386 0.96966 17.1147 2.96785C12.2908 4.96603 8.1677 8.3498 5.26688 12.6912C2.36605 17.0327 0.817749 22.1366 0.817749 27.358C0.817749 32.5794 2.36605 37.6835 5.26688 42.025C8.1677 46.3664 12.2908 49.7502 17.1147 51.7484C21.9386 53.7465 27.2467 54.2694 32.3677 53.2508C37.4888 52.2322 42.1929 49.7179 45.885 46.026C48.3366 43.5745 50.2813 40.6643 51.6081 37.4613C52.9349 34.2582 53.6178 30.825 53.6178 27.358C53.6178 23.891 52.9349 20.458 51.6081 17.255C50.2813 14.0519 48.3366 11.1415 45.885 8.69001ZM40.154 22.556L38.389 24.3211L30.679 32.0311C29.063 33.6411 27.4513 35.253 25.844 36.867C25.3332 37.3624 24.6495 37.6395 23.938 37.6395C23.2264 37.6395 22.5429 37.3624 22.032 36.867C21.716 36.549 21.399 36.2331 21.09 35.9171C18.8607 33.6811 16.6317 31.4478 14.403 29.2171C13.8978 28.7117 13.614 28.0264 13.6141 27.3118C13.6142 26.5972 13.8981 25.9118 14.4035 25.4066C14.9089 24.9013 15.5943 24.6177 16.3089 24.6177C17.0235 24.6178 17.7088 24.9017 18.214 25.4071C18.53 25.7221 18.847 26.0381 19.156 26.3551C20.756 27.9551 22.35 29.5533 23.938 31.15L31.507 23.5802L36.342 18.7462C36.8568 18.2896 37.5264 18.0467 38.2141 18.0672C38.9019 18.0878 39.5558 18.3703 40.0424 18.8568C40.529 19.3432 40.8115 19.9969 40.8322 20.6846C40.853 21.3724 40.6104 22.0421 40.154 22.557",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }