var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c("PinnedMessage"),
      _c(
        "div",
        { attrs: { id: "hero" } },
        [
          _c(
            "Slick",
            { ref: "slick-home-hero", attrs: { options: _vm.slickOptions } },
            _vm._l(_vm.slides, function (slide, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy:background-image",
                      value: slide.wallpaper,
                      expression: "slide.wallpaper",
                      arg: "background-image",
                    },
                  ],
                  key: index,
                  staticClass: "hero__slide",
                },
                [
                  slide.credits
                    ? _c("div", {
                        staticClass: "slick-credits",
                        domProps: { innerHTML: _vm._s(slide.credits) },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "hero__search-form top" }, [
                    _c("div", { staticClass: "homepageTitle" }, [
                      _c("h2", { staticClass: "title" }, [
                        _vm.mixinGetIntegrationInfo().mode == "booking"
                          ? _c("div", { staticClass: "titleBoxsetLogin" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                  Stai prenotando con il\n                "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("BoxsetResume", {
                                    attrs: {
                                      focus:
                                        _vm.mixinGetIntegrationInfo()
                                          .integration.salabam.focus,
                                      band: _vm.mixinGetIntegrationInfo()
                                        .integration.salabam.band,
                                      nights:
                                        _vm.mixinGetIntegrationInfo()
                                          .integration.salabam.nights,
                                      bookingHidePrice:
                                        _vm.$config.guiSettings
                                          .bookingHidePrice,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(slide.title))]),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "hero__search-form down" }, [
            _c("div", { staticClass: "tabs" }, [
              _c("div", { staticClass: "tabsHead" }, [
                _c(
                  "div",
                  {
                    class:
                      "tabHead " + (_vm.activeTabIndex == 0 ? "activeTab" : ""),
                    on: {
                      click: function ($event) {
                        return _vm.updateActiveTabIndex(0)
                      },
                    },
                  },
                  [_vm._v("Cerca una destinazione")]
                ),
                _vm.mixinGetIntegrationInfo().mode == "boxset"
                  ? _c(
                      "div",
                      {
                        class:
                          "tabHead " +
                          (_vm.activeTabIndex == 1 ? "activeTab" : ""),
                        on: {
                          click: function ($event) {
                            return _vm.updateActiveTabIndex(1)
                          },
                        },
                      },
                      [_vm._v("Scegli il voucher perfetto")]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "tabsBody" }, [
                _vm.activeTabIndex == 0
                  ? _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("SearchForm", {
                          on: { "submit-search": _vm.submitSearch },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeTabIndex == 1
                  ? _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("SearchBoxset", {
                          on: { "submit-boxset": _vm.submitBoxset },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "section" },
        [
          _c(
            "b-container",
            { staticClass: "helpBar", attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", xl: "8", xxl: "9" } },
                    [_c("Help")],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", xl: "4", xxl: "3" } },
                    [_c("MiniVp")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "homeContent" } }, [
        _vm.$config.bestSellersBoxsets &&
        _vm.mixinGetIntegrationInfo().mode == "boxset"
          ? _c(
              "section",
              { staticClass: "section" },
              [
                _c(
                  "b-container",
                  { attrs: { fluid: "" } },
                  [_c("BestSellers")],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$config.guiSettings.showInSalabam
          ? _c(
              "section",
              { staticClass: "section" },
              [
                _c(
                  "b-container",
                  { attrs: { fluid: "" } },
                  [_c("InSalabamSlider")],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$config.posts.category != 0
          ? _c(
              "section",
              { staticClass: "section" },
              [
                _c(
                  "b-container",
                  { attrs: { fluid: "", id: "in-salabam" } },
                  [
                    _c("PostSlider", {
                      attrs: { category: _vm.$config.posts.category },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "section",
          { staticClass: "section" },
          [
            _c("b-container", { attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "staticContent giftCardBanner" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "textCol",
                          attrs: { sm: "12", lg: "6" },
                        },
                        [
                          _c("div", { staticClass: "inner" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mb-4" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "logo" },
                                  [_c("SvgLogoNoPayoff")],
                                  1
                                ),
                                _c(
                                  "h3",
                                  { staticClass: "common-title ml-4 mb-0" },
                                  [
                                    _vm._v("Da oggi è anche un"),
                                    _c("br"),
                                    _vm._v("elegante cofanetto regalo"),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "boxed" },
                              [
                                _c("p", [
                                  _vm._v(
                                    "\n                    Per i tuoi regali di Natale unisci l'esperienza online di Salabam alla possibilità di donare un cofanetto prezioso con all'interno una bellissima gift card.\n                    "
                                  ),
                                  _c("br"),
                                  _c("strong", [
                                    _vm._v(
                                      "\n                      Scatola e spedizione in omaggio! Per aziende e privati!\n                    "
                                    ),
                                  ]),
                                ]),
                                _c("br"),
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.mixinGoTo("gift-cards", {})
                                      },
                                    },
                                  },
                                  [_vm._v("Scopri di più")]
                                ),
                              ],
                              1
                            ),
                            _c("br"),
                            _c("p", { staticClass: "small text-right" }, [
                              _vm._v(
                                "\n                    * non disponibile per utenti di welfare aziendale\n                  "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "splashCol",
                          attrs: { sm: "12", lg: "6" },
                        },
                        [
                          _c("div", { staticClass: "splashImgWrapper" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/homepage-banner-giftcard.jpg"),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "section" },
          [
            _c(
              "b-container",
              { staticClass: "purchaseSalabam", attrs: { fluid: "" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("span", { staticClass: "common-title" }, [
                        _vm._v(
                          "Acquista un Salabam con un Buono sconto o un Buono acquisto"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("div", { staticClass: "panels" }, [
                  _vm.mixinUseTicketCompliments()
                    ? _c("div", { staticClass: "single" }, [
                        _c(
                          "div",
                          { staticClass: "panel panel-gray etc" },
                          [_c("EdenredLoginModal")],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c("CopyScadenza", {
                              attrs: {
                                default:
                                  "Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro {value} mesi!",
                                promoMonths:
                                  "Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro {value} mesi!",
                                promoFixed:
                                  "Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro il {value} mesi! (in promozione fino al {promoValidUntil}, invece dei tradizionali {defaultMonths} mesi)",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.mixinUsePromoshopping()
                    ? _c("div", { staticClass: "single" }, [
                        _c(
                          "div",
                          { staticClass: "panel panel-gray ps" },
                          [_c("SvgLogoPromoshopping")],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dignissim convallis aenean et tortor at."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "single pointer",
                      on: {
                        click: function ($event) {
                          return _vm.mixinGoTo("login-salabam", {})
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "panel panel-gray salabam" }, [
                        _c("h3", [_vm._v("Hai una gift card?")]),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/gift-card.png"),
                          },
                        }),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Hai ricevuto una card Salabam in regalo con il suo cofanetto? Prenotare il tuo viaggio è facile, clicca sul banner qui sopra ed inserisci il codice a 16 cifre."
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "single highlight" }, [
                    _c("div", { staticClass: "panel panel-primary" }, [
                      _c("div", { staticClass: "highlightContent" }, [
                        _c("strong", [_vm._v("Salabam per l'azienda")]),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n                Nella sua versione digitale o come card di design col suo cofanetto, Salabam è un'ottimo strumento per le tue attività di marketing, regalistica aziendale e per le tue promozioni.\n                "
                        ),
                        _c("br"),
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "float-right" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { name: "servizi-business" } } },
                              [
                                _c(
                                  "b-button",
                                  { attrs: { variant: "secondary" } },
                                  [_vm._v("Scopri di più")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "section" },
          [
            _c("b-container", { attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "staticContent" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "textCol",
                          attrs: { sm: "12", lg: "6" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inner" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "logo" },
                                    [_c("SvgLogoNoPayoff")],
                                    1
                                  ),
                                  _c(
                                    "h3",
                                    { staticClass: "common-title ml-4 mb-0" },
                                    [_vm._v("è un voucher per viaggiare")]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { clear: "both" } }),
                              _c("br"),
                              _c("p", [
                                _vm._v(
                                  "\n                  Per acquistare un voucher Salabam puoi utilizzare la tua carta di credito, il tuo welfare aziendale o Buoni sconto Salabam"
                                ),
                                _vm.mixinUseTicketCompliments()
                                  ? _c("span", [
                                      _vm._v(", Ticket Compliments Edenred"),
                                    ])
                                  : _vm._e(),
                                _vm.mixinUsePromoshopping()
                                  ? _c("span", [_vm._v(", Promoshopping")])
                                  : _vm._e(),
                                _vm._v(".\n                "),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("CopyScadenza", {
                                    attrs: {
                                      default:
                                        "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto.",
                                      promoMonths:
                                        "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto.",
                                      promoFixed:
                                        "Acquisti adesso, prenoti entro il {value}.",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.scrollTop()
                                    },
                                  },
                                },
                                [_vm._v("Scopri le disponibilità")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "splashCol",
                          attrs: { sm: "12", lg: "6" },
                        },
                        [
                          _c("div", {
                            staticClass: "staticSplash",
                            style: {
                              "background-image":
                                "url('" +
                                require("@/assets/static-content-homepage.jpg") +
                                "')",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "section separator" },
          [_c("b-container", { attrs: { fluid: "" } }, [_c("hr")])],
          1
        ),
        _c(
          "section",
          { staticClass: "section" },
          [
            _c("b-container", { attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "partners" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12" } }, [
                        _c("h3", { staticClass: "common-title" }, [
                          _vm._v("I nostri partners"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/aon.png"),
                                expression:
                                  "require('../../assets/partners/aon.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/autogrill.png"),
                                expression:
                                  "require('../../assets/partners/autogrill.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/beneficy.png"),
                                expression:
                                  "require('../../assets/partners/beneficy.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/confindustria-vicenza.png"),
                                expression:
                                  "require('../../assets/partners/confindustria-vicenza.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/doubleyou.png"),
                                expression:
                                  "require('../../assets/partners/doubleyou.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/eudaimon.png"),
                                expression:
                                  "require('../../assets/partners/eudaimon.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/fiamm.png"),
                                expression:
                                  "require('../../assets/partners/fiamm.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/health-italia.png"),
                                expression:
                                  "require('../../assets/partners/health-italia.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/jakala.png"),
                                expression:
                                  "require('../../assets/partners/jakala.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/jointly.png"),
                                expression:
                                  "require('../../assets/partners/jointly.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/konica-minolta.png"),
                                expression:
                                  "require('../../assets/partners/konica-minolta.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/mooney.png"),
                                expression:
                                  "require('../../assets/partners/mooney.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/motivation-network.png"),
                                expression:
                                  "require('../../assets/partners/motivation-network.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/pellegrini.png"),
                                expression:
                                  "require('../../assets/partners/pellegrini.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/poste-welfare.png"),
                                expression:
                                  "require('../../assets/partners/poste-welfare.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/pragma-comunicazione.png"),
                                expression:
                                  "require('../../assets/partners/pragma-comunicazione.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/promarsa.png"),
                                expression:
                                  "require('../../assets/partners/promarsa.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/promoshopping.png"),
                                expression:
                                  "require('../../assets/partners/promoshopping.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/proxima.png"),
                                expression:
                                  "require('../../assets/partners/proxima.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/randstad.png"),
                                expression:
                                  "require('../../assets/partners/randstad.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/safilo.png"),
                                expression:
                                  "require('../../assets/partners/safilo.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/sodexo.png"),
                                expression:
                                  "require('../../assets/partners/sodexo.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/ticket-compliments.png"),
                                expression:
                                  "require('../../assets/partners/ticket-compliments.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/timeswapp.png"),
                                expression:
                                  "require('../../assets/partners/timeswapp.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/unindustria.png"),
                                expression:
                                  "require('../../assets/partners/unindustria.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/upday.png"),
                                expression:
                                  "require('../../assets/partners/upday.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/welfare-bit.png"),
                                expression:
                                  "require('../../assets/partners/welfare-bit.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/well-work.png"),
                                expression:
                                  "require('../../assets/partners/well-work.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "partner",
                          attrs: { sm: "12", md: "4", lg: "3", xl: "2" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("../../assets/partners/willis.png"),
                                expression:
                                  "require('../../assets/partners/willis.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      false
        ? _c("ScrollDown", { attrs: { targetId: "in-salabam" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }