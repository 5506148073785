var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "600",
        height: "200",
        viewBox: "0 0 600 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M287.009 119.33C379.775 119.33 467.315 140.889 543.966 178.779C468.404 135.009 380.646 109.967 287.009 109.967C193.373 109.967 105.615 135.009 30.0527 178.779C106.704 140.671 194.244 119.33 287.009 119.33Z",
          fill: "#E8785A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.6408 116.936C48.5605 116.064 52.0446 114.54 55.0933 112.58C58.1419 110.62 60.755 107.79 62.7149 104.523C64.6747 101.257 65.5458 97.1194 65.5458 92.3287C65.5458 88.6267 64.8925 85.5781 63.3682 82.7472C61.8438 80.1341 60.1017 77.9565 57.7064 75.9967C55.311 74.2546 52.6979 72.7303 49.8671 71.6415C47.0362 70.5527 43.9875 69.6816 41.1567 69.0283C36.3659 67.9395 32.664 66.8507 30.4864 65.9797C28.3088 65.1087 27.22 63.5843 27.22 61.4067C27.22 59.2291 28.3088 57.4871 30.2687 56.616C33.0995 55.3095 36.1482 54.874 39.8501 55.5272C42.2454 55.9628 44.6408 56.6161 46.8184 57.9226C48.996 59.2292 51.1736 60.5357 52.9157 62.2778L66.6346 48.1234C62.9326 44.6393 58.1419 41.8084 52.6979 40.0663C47.2539 38.3242 41.5922 37.4531 35.9304 37.4531C32.2285 37.4531 28.5266 37.8887 24.8247 38.9775C21.1227 39.8485 17.6386 41.3728 14.5899 43.5504C11.5413 45.5103 8.92817 48.1234 7.18609 51.3898C5.22625 54.6562 4.3552 58.5759 4.3552 63.1488C4.3552 66.633 5.0085 69.6816 6.31506 72.2947C7.62162 74.9078 9.36369 77.0854 11.5413 78.8275C13.7189 80.5696 15.8965 81.8762 18.7274 82.965C21.3405 84.0538 23.9536 84.707 26.7845 85.3603C32.0107 86.6669 35.7126 87.7557 38.3258 88.8445C40.7211 89.9333 42.0277 91.6754 42.0277 94.0707C42.0277 96.4661 40.9389 98.2082 38.9791 99.297C37.0192 100.168 35.0594 100.821 32.8818 100.821C29.1798 100.821 25.6957 99.9502 22.4293 98.4259C19.1629 96.6838 16.332 94.724 14.1544 92.3287L0 107.136C3.91968 111.056 8.92818 113.887 15.0255 115.847C20.905 117.807 27.0022 118.895 33.3173 118.895C37.0192 118.242 40.7211 117.807 44.6408 116.936Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M107.354 69.0302C101.475 69.6835 96.2487 70.7723 91.458 72.7321C86.6673 74.4742 82.7476 77.0874 79.9167 80.5715C76.8681 83.8379 75.3438 88.4109 75.3438 93.8549C75.3438 98.4278 76.2148 102.13 77.9569 105.178C79.6989 108.227 81.8766 110.622 84.7074 112.582C87.3206 114.324 90.3692 115.849 93.6356 116.502C96.902 117.373 100.168 117.808 103.217 117.808C107.79 117.808 112.145 116.937 116.065 115.195C119.985 113.453 122.815 111.058 124.775 107.792H125.211C125.211 112.364 128.913 115.849 133.268 115.849H148.076V77.5229C148.076 70.5546 147.422 64.675 146.116 59.4488C144.809 54.4403 142.849 50.3029 140.018 46.8187C137.188 43.5523 133.703 41.157 129.13 39.4149C124.775 37.8906 119.331 37.0195 112.798 37.0195C106.483 37.0195 100.386 38.1083 94.5066 40.2859C88.6271 42.4635 83.4009 45.7299 78.8279 49.8674L91.458 63.5862C93.8533 61.4086 96.6842 59.6665 99.9506 58.1422C103.217 56.6179 106.701 55.7469 110.185 55.7469C114.105 55.7469 117.371 56.6179 119.985 58.36C122.598 60.1021 123.904 62.933 123.904 66.6349V67.9414C118.896 67.9414 113.234 68.3769 107.354 69.0302ZM125.211 82.5313V85.1445C125.211 89.9352 123.904 93.6371 121.073 96.468C118.46 99.2988 114.541 100.605 109.314 100.605C108.008 100.605 106.919 100.388 105.395 100.17C104.088 99.9522 102.999 99.5166 101.91 98.8633C100.822 98.2101 99.9506 97.339 99.2973 96.468C98.6441 95.3792 98.2085 94.2904 98.2085 92.7661C98.2085 90.5885 98.8618 88.6287 100.386 87.3221C101.91 86.0155 103.653 84.9267 105.83 84.2734C108.008 83.6201 110.621 82.9669 113.234 82.7491C116.065 82.5314 118.896 82.3136 121.509 82.3136H125.211V82.5313Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M185.747 0H160.27V115.631H184.223C184.876 115.413 185.312 115.413 185.965 115.195V0H185.747Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M266.54 77.3081C266.54 70.3397 265.887 64.4602 264.581 59.2339C263.274 54.2255 261.314 50.088 258.483 46.6039C255.652 43.3375 252.168 40.9421 247.595 39.2001C243.24 37.6757 237.796 36.8047 231.263 36.8047C224.948 36.8047 218.851 37.8935 212.971 40.0711C207.092 42.2487 201.866 45.5151 197.293 49.6525L209.923 63.3714C212.318 61.1938 215.149 59.4517 218.415 57.9274C221.682 56.4031 225.166 55.5321 228.65 55.5321C232.57 55.5321 235.836 56.4031 238.449 58.1451C241.062 59.8872 242.369 62.7181 242.369 66.4201V67.7266C236.925 67.7266 231.263 67.9444 225.384 68.5977C219.504 69.2509 214.278 70.3397 209.487 72.2995C204.697 74.0416 200.777 76.6547 197.946 80.1389C194.897 83.4053 193.373 87.9783 193.373 93.4223C193.373 97.9952 194.244 101.697 195.986 104.746C197.728 107.794 199.906 110.19 202.519 111.932C223.424 108.448 244.764 106.27 266.105 105.399V77.3081H266.54ZM243.676 85.1474C243.676 89.9381 242.369 93.64 239.538 96.4709C236.925 99.3018 233.005 100.608 227.779 100.608C226.473 100.608 225.384 100.391 223.859 100.173C222.553 99.9551 221.464 99.5196 220.375 98.8663C219.286 98.213 218.415 97.3419 217.762 96.4709C217.109 95.3821 216.673 94.2933 216.673 92.769C216.673 90.5914 217.327 88.6316 218.851 87.325C220.375 86.0185 222.117 84.9296 224.295 84.2763C226.473 83.6231 229.086 83.1876 231.699 82.7521C234.53 82.5343 237.361 82.3165 239.974 82.3165H243.24V85.1474H243.676Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M349.942 109.315C351.248 108.227 352.555 106.92 353.644 105.614C356.692 101.912 359.088 97.5565 360.83 92.7657C362.354 87.975 363.225 82.7488 363.225 77.5226C363.225 72.2963 362.354 67.0701 360.612 62.2794C358.87 57.4886 356.475 53.1334 353.426 49.4315C350.377 45.7295 346.458 42.8987 342.103 40.7211C337.747 38.5435 332.739 37.4547 327.077 37.4547C321.415 37.4547 316.625 38.5435 312.923 40.7211C309.003 42.8987 305.954 45.2941 303.995 47.6894H303.777V0H278.734V105.396C281.565 105.396 284.396 105.396 287.227 105.396C308.132 105.396 329.255 106.702 349.942 109.315ZM303.995 70.3364C304.866 67.9411 306.172 65.9812 307.696 64.0214C309.221 62.2793 311.181 60.755 313.358 59.6662C315.536 58.5774 318.149 57.9242 320.98 57.9242C324.028 57.9242 326.642 58.5774 328.819 59.6662C330.997 60.755 332.957 62.2793 334.481 64.0214C336.005 65.7635 337.094 67.9411 337.965 70.1187C338.836 72.514 339.054 74.9094 339.054 77.3048C339.054 79.7001 338.618 82.0955 337.965 84.4908C337.094 86.8862 336.005 89.0638 334.481 90.8059C332.957 92.5479 330.997 94.0723 328.819 95.1611C326.642 96.2499 324.028 96.9032 320.98 96.9032C318.149 96.9032 315.536 96.2499 313.358 95.1611C311.181 94.0723 309.221 92.5479 307.696 90.8059C306.172 89.0638 304.866 86.8862 303.995 84.4908C303.124 82.0955 302.688 79.7001 302.688 77.3048C302.688 75.1272 303.124 72.7318 303.995 70.3364Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M407.866 114.98C411.785 113.238 414.616 110.843 416.576 107.577H417.012C417.012 112.15 420.713 115.634 425.069 115.634H439.876V77.3081C439.876 70.3397 439.223 64.4602 437.917 59.2339C436.61 54.2255 434.65 50.088 431.819 46.6039C428.988 43.3375 425.504 40.9421 420.931 39.2001C416.576 37.6757 411.132 36.8047 404.599 36.8047C398.284 36.8047 392.187 37.8935 386.307 40.0711C380.428 42.2487 375.202 45.5151 370.629 49.6525L383.259 63.3714C385.654 61.1938 388.485 59.4517 391.751 57.9274C395.018 56.4031 398.502 55.5321 401.986 55.5321C405.906 55.5321 409.172 56.4031 411.785 58.1451C414.398 59.8872 415.705 62.7181 415.705 66.4201V67.7266C410.261 67.7266 404.599 67.9444 398.72 68.5977C392.84 69.2509 387.614 70.3397 382.823 72.2995C378.033 74.0416 374.113 76.6547 371.282 80.1389C368.233 83.4053 366.709 87.9783 366.709 93.4223C366.709 97.9952 367.58 101.697 369.322 104.746C371.064 107.794 373.242 110.19 376.073 112.15C376.726 112.585 377.379 113.021 378.033 113.238C384.565 114.327 391.098 115.634 397.631 117.158C401.551 117.158 404.817 116.287 407.866 114.98ZM391.534 96.6887C390.88 95.5999 390.445 94.511 390.445 92.9867C390.445 90.8091 391.098 88.8493 392.622 87.5427C394.147 86.2362 395.889 85.1474 398.066 84.4941C400.244 83.8408 402.857 83.4053 405.47 82.9698C408.301 82.7521 411.132 82.5343 413.745 82.5343H417.012V85.1474C417.012 89.9381 415.705 93.64 412.874 96.4709C410.261 99.3018 406.341 100.608 401.115 100.608C399.809 100.608 398.72 100.391 397.195 100.173C395.889 99.9551 394.8 99.5196 393.711 98.8663C393.058 98.4308 392.187 97.7775 391.534 96.6887Z",
          fill: "#292E44",
        },
      }),
      _c("path", {
        attrs: {
          d: "M572.927 57.7048C572.056 54.0029 570.314 50.5187 568.354 47.6879C566.177 44.6392 563.346 42.2439 559.861 40.284C556.377 38.3242 552.022 37.4531 546.796 37.4531C541.134 37.4531 536.126 38.5419 532.206 40.9373C528.286 43.3327 525.02 46.3813 522.407 50.5187C520.229 46.3813 517.181 43.1149 513.479 40.9373C509.559 38.7597 504.986 37.6709 499.542 37.6709C496.711 37.6709 494.098 38.1064 491.703 38.7597C489.307 39.413 487.13 40.5018 485.17 41.5906C483.21 42.6794 481.468 44.2037 480.161 45.728C478.855 47.2524 477.548 48.7766 476.677 50.5187H476.459V40.0663H452.07V116.282H477.113V74.9079C477.113 70.3349 478.202 66.4153 480.379 63.3666C482.557 60.1002 485.605 58.5759 489.743 58.5759C493.445 58.5759 496.276 59.8824 498.018 62.4955C499.76 65.1086 500.631 68.5928 500.631 72.948V116.5H525.673V75.3434C525.673 73.1658 525.891 71.2059 526.544 69.2461C526.98 67.2863 527.851 65.5442 528.94 64.0199C530.028 62.4955 531.335 61.189 532.859 60.3179C534.384 59.4469 536.343 59.0114 538.521 59.0114C540.699 59.0114 542.658 59.4469 543.965 60.3179C545.272 61.189 546.36 62.2778 547.231 63.8021C548.102 65.3264 548.538 66.8507 548.973 69.0283C549.191 70.9882 549.409 73.1658 549.409 75.1256V116.718H574.451V69.6816C574.234 65.1087 573.798 61.4068 572.927 57.7048Z",
          fill: "#292E44",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }