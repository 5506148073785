var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "miniVp" }, [
    _c(
      "div",
      { staticClass: "vp", attrs: { id: _vm._uid + "popover-target-minivp" } },
      [
        _c("div", { staticClass: "vpInner" }, [
          _c("span", { staticClass: "vpCopy" }, [
            _c("div", { staticClass: "vpIcon" }, [_c("SvgCalendar")], 1),
            _c(
              "span",
              [
                _c("CopyScadenza", {
                  attrs: {
                    default: "Validità<br/> {value} mesi",
                    promoMonths: "Validità<br/> {value} mesi",
                    promoFixed: "Validità fino al {value}",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "vpInfo",
              on: {
                click: function ($event) {
                  return _vm.mixinGoTo("faq", {})
                },
              },
            },
            [_vm._v("info")]
          ),
        ]),
        _c(
          "b-popover",
          {
            attrs: {
              target: _vm._uid + "popover-target-minivp",
              triggers: "hover",
              placement: "bottom",
            },
          },
          [
            _c("CopyScadenza", {
              attrs: {
                default:
                  "Acquista oggi, avrai {value} mesi per scegliere e viaggiare",
                promoMonths:
                  "Acquista oggi, avrai {value} mesi per scegliere e viaggiare",
                promoFixed:
                  "Acquista oggi, avrai tempo fino al {value} per scegliere e viaggiare",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "b-popover",
            rawName: "v-b-popover.hover.bottom",
            value:
              "Ricontrolliamo direttamente le prenotazioni dopo la conferma",
            expression:
              "'Ricontrolliamo direttamente le prenotazioni dopo la conferma'",
            modifiers: { hover: true, bottom: true },
          },
        ],
        staticClass: "vp",
      },
      [
        _c("div", { staticClass: "vpInner" }, [
          _c("span", { staticClass: "vpCopy" }, [
            _c("div", { staticClass: "vpIcon" }, [_c("SvgCheck")], 1),
            _vm._m(0),
          ]),
          _c(
            "div",
            {
              staticClass: "vpInfo",
              on: {
                click: function ($event) {
                  return _vm.mixinGoTo("assistenza-viaggi-sicuro", {})
                },
              },
            },
            [_vm._v("info")]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "b-popover",
            rawName: "v-b-popover.hover.bottom",
            value: "Strutture selezionate in base a qualità e professionalità",
            expression:
              "'Strutture selezionate in base a qualità e professionalità'",
            modifiers: { hover: true, bottom: true },
          },
        ],
        staticClass: "vp",
      },
      [
        _c("div", { staticClass: "vpInner" }, [
          _c("span", { staticClass: "vpCopy" }, [
            _c("div", { staticClass: "vpIcon" }, [_c("SvgHeart")], 1),
            _vm._m(1),
          ]),
          _c(
            "div",
            {
              staticClass: "vpInfo",
              on: {
                click: function ($event) {
                  return _vm.mixinGoTo("assistenza-viaggi-sicuro", {})
                },
              },
            },
            [_vm._v("info")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Double"), _c("br"), _vm._v("Check")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("White"), _c("br"), _vm._v("List")])
  },
]
render._withStripped = true

export { render, staticRenderFns }