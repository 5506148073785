<template>
    <div class="miniVp">
        <!--<div class="vp" v-b-popover.hover.bottom="'Acquista oggi, avrai 16 mesi per scegliere e viaggiare'">-->
        <div class="vp" :id="_uid+'popover-target-minivp'">
            <div class="vpInner">
                <span class="vpCopy">
                    <div class="vpIcon"><SvgCalendar /></div>
                    <span>
                        <CopyScadenza
                            default="Validità<br/> {value} mesi"
                            promoMonths="Validità<br/> {value} mesi"
                            promoFixed="Validità fino al {value}"
                        />
                    </span>
                </span>
                <div class="vpInfo" @click="mixinGoTo('faq',{})">info</div>
            </div>
            <b-popover :target="_uid+'popover-target-minivp'" triggers="hover" placement="bottom">
                <CopyScadenza
                    default="Acquista oggi, avrai {value} mesi per scegliere e viaggiare"
                    promoMonths="Acquista oggi, avrai {value} mesi per scegliere e viaggiare"
                    promoFixed="Acquista oggi, avrai tempo fino al {value} per scegliere e viaggiare" 
                />
            </b-popover>
        </div>
        <div class="vp" v-b-popover.hover.bottom="'Ricontrolliamo direttamente le prenotazioni dopo la conferma'">
            <div class="vpInner">
                <span class="vpCopy">
                    <div class="vpIcon"><SvgCheck /></div>
                    <span>Double<br>Check</span>
                </span>
                <div class="vpInfo" @click="mixinGoTo('assistenza-viaggi-sicuro',{})">info</div>
            </div>
        </div>
        <div class="vp" v-b-popover.hover.bottom="'Strutture selezionate in base a qualità e professionalità'">
            <div class="vpInner">
                <span class="vpCopy">
                    <div class="vpIcon"><SvgHeart /></div>
                    <span>White<br>List</span>
                </span>
                <div class="vpInfo" @click="mixinGoTo('assistenza-viaggi-sicuro',{})">info</div>
            </div>
        </div>
        <!--
        <div class="miniVpText">
            <p v-html="miniVpText">

            </p>
            <p>
               Il nostro staff verifica di nuovo<br/>la prenotazione prima del tuo arrivo
            </p>
            <p>
              Proponiamo solo strutture che superano<br/>un attento controllo di qualità ed esperienza
            </p>
        </div>
        -->
    </div>
</template>

<script>
import SvgCalendar from '../../svg/calendar'
import SvgCheck from '../../svg/check'
import SvgHeart from '../../svg/heart'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'

export default {
    name: 'mini-value-proposition',
    props:
    {
        
    },
    components:
    {
        SvgCalendar,
        SvgCheck,
        SvgHeart,     
        CopyScadenza,  
    },
    data (){
      return {
        miniVpTitle: this.$config.guiSettings.vouchersValidity.miniVpTitle,
        miniVpText: this.$config.guiSettings.vouchersValidity.miniVpText,
      }
    },
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .miniVp
    {
        @include media-breakpoint-up(md){
            display:flex;
            flex-wrap:wrap;
            align-items: center;
            height: 100%;
        }

        .miniVpText
        {
            @include media-breakpoint-down(xs)
            {
                display:none;
            }
            flex:1;
            min-width:100%;
            p
            {
                font-size: 12px;
                text-align:center;
                margin:auto;
                margin-top:1rem;
                margin-bottom:0;
                width: calc(100% - #{$grid-gutter-width});
                max-width: 340px;
            }
        }
        .vp
        {
            //flex:1;
            overflow:hidden;
            cursor:pointer;
            height: 100%; 

            .vpInner
            {
                background:$primary;
                color:$white;
                font-weight: bold;
                border-radius: $border-radius-large;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex:1;
                overflow:hidden;
                cursor:pointer;
                height: 100%;
            }
        }
        .vpCopy
        {
            flex:1;
            display:flex;
            padding:$grid-gutter-width/4;
            text-align:center;
            align-items: center;
            justify-content: center;
        }
        .vpInfo
        {
            background:$light-blue;
            border-radius: $border-radius-large;
            min-height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition:$transition-fade;
            cursor:pointer;
            &:hover
            {
                background:rgba($light-blue,0.75);
            }
        }
        .vpIcon
        {
            //height:65px;
            margin-right:1rem;
        }
        svg
        {
            margin:auto;
            //height:auto;
            //width:48px;
            //max-width: 48px;
            width:auto;
            height:24px;
            display:block;
            //margin-bottom:1rem;
            path
            {
                fill:$white;
            }
        }

        .miniVpText p:not(:last-child)
        {
            //display:none;
        }
        

        .miniVpText
        {
            position:relative;
            min-height: 3rem;
            p
            {
                opacity:0;
                transition: $transition-fade;
                position:absolute;
                left:0;
                right:0;
                top:0;
                width:100%;
            }
        }

        .vp:nth-child(1):hover ~ .miniVpText p:nth-child(1)
        {
            opacity:1;
        }
        .vp:nth-child(2):hover ~ .miniVpText p:nth-child(2)
        {
            opacity:1;
        }
        .vp:nth-child(3):hover ~ .miniVpText p:nth-child(3)
        {
            opacity:1;
        }

        $vps: 1,2,3;
        @each $vp in $vps {
            .vp:nth-child(#{$vp}):hover ~ .miniVpText p:nth-child(#{$vp}),
            .vp:nth-child(#{$vp}):focus ~ .miniVpText p:nth-child(#{$vp})
            {
                 display:block;
            }
        }

        @include media-breakpoint-up(sm)
        {

        }

        @include media-breakpoint-up(md)
        {
            .vp
            {
                flex:1;
            
                &:not(:nth-child(3))
                {
                    padding-right:$grid-gutter-width/4;
                }
            }
        }

        @include media-breakpoint-up(lg)
        {
        }

        @include media-breakpoint-up(xl)
        {
            .vpCopy
            {
                flex-direction: column;
                margin:0;
                span
                {
                    display: inline-block;
                    min-height: 50px;
                }
            }
            .vpIcon
            {
                margin-right:0;
            }
            svg
            {
                height: 38px;
                margin-bottom: 1rem;
            }
        }
        @include hack-ie11
        {
            .vp
            {
                min-height:200px;
                .vpInner
                {
                    display:block;
                }
                .vpCopy
                {
                    display:block;
                }
                .vpInfo
                {
                    padding-top:15px;
                }
            }
        }
    }
</style>