<template>
  <div id="home">

    <PinnedMessage/>
    
    <div id="hero">

      <Slick ref="slick-home-hero" :options="slickOptions">
        <div v-for="(slide,index) in slides" v-bind:key="index" class="hero__slide" v-lazy:background-image="slide.wallpaper">
            <div class="slick-credits" v-if="slide.credits" v-html="slide.credits"></div>
          <div class="hero__search-form top">
            <div class="homepageTitle">
              <h2 class="title">
                <div v-if="mixinGetIntegrationInfo().mode == 'booking'" class="titleBoxsetLogin">
                  <span>
                    Stai prenotando con il
                  </span>
                  <div>
                    <BoxsetResume
                        :focus="mixinGetIntegrationInfo().integration.salabam.focus"
                        :band="mixinGetIntegrationInfo().integration.salabam.band"
                        :nights="mixinGetIntegrationInfo().integration.salabam.nights"
                        :bookingHidePrice="$config.guiSettings.bookingHidePrice"
                    />
                  </div>
                </div>
                <span v-else>{{slide.title}}</span>
              </h2>
            </div>
          </div>
        </div>
      </Slick>

      <div class="hero__search-form down">
        <div class="tabs">
          <div class="tabsHead">
            <div @click="updateActiveTabIndex(0)" :class="'tabHead ' + (activeTabIndex == 0 ? 'activeTab' : '')">Cerca una destinazione</div>
            <div v-if="mixinGetIntegrationInfo().mode == 'boxset'" @click="updateActiveTabIndex(1)" :class="'tabHead ' + (activeTabIndex == 1 ? 'activeTab' : '')">Scegli il voucher perfetto</div>
          </div>
          <div class="tabsBody">
            <div class="tab" v-if="activeTabIndex == 0"><SearchForm v-on:submit-search="submitSearch" /></div>
            <div class="tab" v-if="activeTabIndex == 1"><SearchBoxset v-on:submit-boxset="submitBoxset"/></div>
          </div>
        </div>
      </div>

    </div>

    <!--helpBar-->
    <section class="section">
      <b-container fluid class="helpBar">
        <b-row>
          <b-col sm="12" xl="8" xxl="9">
            <Help />
          </b-col>
          <b-col sm="12" xl="4" xxl="3">
            <MiniVp />
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div id="homeContent">

      <!--bestSellers-->
      <section class="section" v-if="$config.bestSellersBoxsets && mixinGetIntegrationInfo().mode == 'boxset'" >
        <b-container fluid>
          <BestSellers />
        </b-container>
      </section>

      <!--inSalabamSlider-->
      <section class="section" v-if="$config.guiSettings.showInSalabam">
        <b-container fluid>
          <InSalabamSlider />
        </b-container>
      </section>


      <!--postSlider-->
      <section class="section" v-if="$config.posts.category != 0">
        <b-container fluid id="in-salabam">
          <PostSlider :category="$config.posts.category" />
        </b-container>
      </section>


      <!--staticContent gift card-->
      <section class="section">
        <b-container fluid>
          <div class="staticContent giftCardBanner">
            <b-row>
              <b-col sm="12" lg="6" class="textCol">
                <div class="inner">

                  <div class="d-flex align-items-center mb-4">
                    <div class="logo"><SvgLogoNoPayoff /></div>
                    <h3 class="common-title ml-4 mb-0">Da oggi è anche un<br>elegante cofanetto regalo</h3>
                  </div>
                  
                  <div class="boxed">
                    <p>
                      Per i tuoi regali di Natale unisci l'esperienza online di Salabam alla possibilità di donare un cofanetto prezioso con all'interno una bellissima gift card.
                      <br>
                      <strong>
                        Scatola e spedizione in omaggio! Per aziende e privati!
                      </strong> 
                    </p>
                    <br>
                    <b-button @click="mixinGoTo('gift-cards',{})">Scopri di più</b-button>
                  </div>

                    <br>
                    <p class="small text-right">
                      * non disponibile per utenti di welfare aziendale
                    </p>
                </div>
              </b-col>
              <b-col sm="12" lg="6" class="splashCol">
                <div class="splashImgWrapper"><img src="@/assets/images/homepage-banner-giftcard.jpg" /></div>
                <!--<div class="staticSplash" v-bind:style="{ 'background-image': 'url(/assets/images/homepage-banner-giftcard.jpg)' }"></div>-->
              </b-col>
            </b-row>
          </div>
        </b-container>
      </section>

      <!--purchaseSalabam-->
      <section class="section">
        <b-container fluid class="purchaseSalabam">
          <b-row>
            <b-col sm="12">
              <span class="common-title">Acquista un Salabam con un Buono sconto o un Buono acquisto</span>
            </b-col>
          </b-row>

          <br><br>

          <div class="panels">
            <div class="single" v-if="mixinUseTicketCompliments()">
              <div class="panel panel-gray etc">
                  <EdenredLoginModal />
              </div>
              <p>
                <CopyScadenza
                    default="Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro {value} mesi!"
                    promoMonths="Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro {value} mesi!"
                    promoFixed="Hai un Ticket Compliments? Usalo per acquistare un voucher Salabam con cui prenotare un viaggio entro il {value} mesi! (in promozione fino al {promoValidUntil}, invece dei tradizionali {defaultMonths} mesi)"
                />
              </p>
            </div>


            <div class="single" v-if="mixinUsePromoshopping()">
              <div class="panel panel-gray ps">
                <SvgLogoPromoshopping />
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dignissim convallis aenean et tortor at.</p>
            </div>

            <div class="single pointer" @click="mixinGoTo('login-salabam', {})">
              <div class="panel panel-gray salabam">
                <h3>Hai una gift card?</h3>
                <img src="@/assets/images/gift-card.png" />
              </div>
              <p>Hai ricevuto una card Salabam in regalo con il suo cofanetto? Prenotare il tuo viaggio è facile, clicca sul banner qui sopra ed inserisci il codice a 16 cifre.</p>
            </div>

            <div class="single highlight">
              <div class="panel panel-primary">
                <div class="highlightContent">
                  <strong>Salabam per l'azienda</strong>
                  <br/><br/>
                  Nella sua versione digitale o come card di design col suo cofanetto, Salabam è un'ottimo strumento per le tue attività di marketing, regalistica aziendale e per le tue promozioni.
                  <br/><br/>
                  <div class="float-right">
                    <router-link :to="{name: 'servizi-business'}"><b-button variant="secondary">Scopri di più</b-button></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-container>
      </section>


      <!--staticContent-->
      <section class="section">
        <b-container fluid>
          <div class="staticContent">
            <b-row>
              <b-col sm="12" lg="6" class="textCol">
                <div class="inner">

                  <div class="d-flex align-items-center">
                    <div class="logo"><SvgLogoNoPayoff /></div>
                    <h3 class="common-title ml-4 mb-0">è un voucher per viaggiare</h3>
                  </div>

                  <div style="clear: both"></div>

                  <br/>

                  <p>
                    Per acquistare un voucher Salabam puoi utilizzare la tua carta di credito, il tuo welfare aziendale o Buoni sconto Salabam<span v-if="mixinUseTicketCompliments()">, Ticket Compliments Edenred</span><span v-if="mixinUsePromoshopping()">, Promoshopping</span>.
                  </p>
                  <p>
                    <CopyScadenza
                        default="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto."
                        promoMonths="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto."
                        promoFixed="Acquisti adesso, prenoti entro il {value}."
                    />
                  </p>
                  <br>
                  <b-button @click="scrollTop()">Scopri le disponibilità</b-button>
                </div>
              </b-col>
              <b-col sm="12" lg="6" class="splashCol">
                <div class="staticSplash" v-bind:style="{ 'background-image': 'url(\'' + require('@/assets/static-content-homepage.jpg') + '\')'}"></div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </section>

      <!--hr-->
      <section class="section separator">
        <b-container fluid>
          <hr>
        </b-container>
      </section>

      <!--partners-->
      <section class="section">
        <b-container fluid>
          <div class="partners">

            <b-row>
              <b-col sm="12">
                  <h3 class="common-title">I nostri partners</h3>
              </b-col>
            </b-row>

            <br><br>

            <b-row>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/aon.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/autogrill.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/beneficy.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/confindustria-vicenza.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/doubleyou.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/eudaimon.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/fiamm.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/health-italia.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/jakala.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/jointly.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/konica-minolta.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/mooney.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/motivation-network.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/pellegrini.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/poste-welfare.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/pragma-comunicazione.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/promarsa.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/promoshopping.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/proxima.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/randstad.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/safilo.png')" /></b-col>
              <!--<b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/sisalpay.png')" /></b-col>-->
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/sodexo.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/ticket-compliments.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/timeswapp.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/unindustria.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/upday.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/welfare-bit.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/well-work.png')" /></b-col>
              <b-col sm="12" md="4" lg="3" xl="2" class="partner"><img v-lazy="require('../../assets/partners/willis.png')" /></b-col>
            </b-row>
          </div>
        </b-container>
      </section>

    </div>

    <ScrollDown :targetId="'in-salabam'" v-if="false"/> <!-- disattivato -->

  </div>
</template>

<script>
  import Vue from 'vue'
  import Slick from 'vue-slick'
  import ScrollDown from './../atoms/scroll-down'
  import PinnedMessage from '../blocks/common/pinned-message'
  import SearchForm from './../blocks/search-form/search-form'
  import SearchBoxset from './../blocks/search-form/search-boxset'
  import Help from './../blocks/footer/help'
  import MiniVp from './../blocks/common/mini-value-proposition'
  import PostSlider from '../blocks/post-slider'
  import InSalabamSlider from '../blocks/in-salabam-slider'
  import BestSellers from '../blocks/best-sellers'
  import SvgLogoNoPayoff from '../svg/logo-no-payoff'
  import BoxsetResume from '../blocks/boxset/boxset-resume'
  import EdenredLoginModal from '../blocks/common/edenred-login-modal'
  import CopyScadenza from '@/components/blocks/common/copy-scadenza'

  export default {
    name: 'not-found',
    metaInfo(){
      return {
        title: 'not found',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
        ]
      }
    },
    components: {
      Slick,
      ScrollDown,
      PinnedMessage,
      SearchForm,
      SearchBoxset,
      Help,
      MiniVp,
      PostSlider,
      InSalabamSlider,
      BestSellers,
      SvgLogoNoPayoff,
      BoxsetResume,
      EdenredLoginModal,
      CopyScadenza,
    },
    props: {
      prerenderStatusCode: {
        type: String,
        default: '200',
      }
    },
    data()
    {
      return {
        activeTabIndex: ( !(this.mixinGetIntegrationInfo().mode == 'booking') && Vue.prototype.$config.guiSettings.homePerfectVoucherActive)? 1 : 0, // pilota il form ricerca in home, inizia con destinazione o voucher perfetto, in booking sempre destinazione
        slides: this.mixinGetIntegrationInfo().mode == 'booking' ? Object.values(Vue.prototype.$config.sliderHome.private) :  Object.values(Vue.prototype.$config.sliderHome.public),
        slickOptions: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots:true,
          fade:true,
          arrows:false,
          autoplay:true,
          speed: 2500,
          autoplaySpeed: Vue.prototype.$config.guiSettings.autoplaySpeedHome,
          responsive: [{
            /*
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
            */
          }]
        },
        miniVpText: this.$config.guiSettings.vouchersValidity.miniVpText + '.',
      }
    },
    methods: {
      submitSearch()
      {
        this.mixinSendMutation('setUnlockUpselling',false)
        this.mixinSendMutation('setTriggerSearch',true)
        this.mixinGoTo('search',{})
      },
      submitBoxset(parameters)
      {
        this.mixinGoTo('search-voucher',{searchParameters: parameters})
      },
      scrollTop(){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      updateActiveTabIndex(tabIndexToActivate){

        // 0 ricerca geografica
        // 1 voucher perfetto

        this.activeTabIndex = tabIndexToActivate

        let action = 'homeTabSearchClick'
        if(tabIndexToActivate == 1) action = 'homeTabVoucherPerfettoClick';

        if(this.$gtm) this.$gtm.trackEvent({
          event: 'customEvent',
          category: 'userBehaviour',
          action: action,
          label: '',
          value: tabIndexToActivate,
        })

        if(window.LogRocket) window.LogRocket.track(action)

      }
    },

    mounted(){

      if(this.$config.guiSettings.loadHome_inSalabamId && this.$config.guiSettings.loadHome_inSalabamId != 0)
      {
        this.mixinGoTo('in-salabam',{
          inSalabamId: (this.$config.guiSettings.loadHome_inSalabamId).toString(),
          inSalabamTitle: '-',
          setGeo: (this.$config.guiSettings.loadHome_inSalabamId_asDb == undefined)
        })
      }
    },

  }
</script>

<style lang="scss">
// vedi _home.scss
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import './../../scss/_custom.scss';

</style>