var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$config.guiSettings.pinnedMessage
    ? _c(
        "div",
        { staticClass: "pinnedMessage" },
        [
          _c(
            "b-alert",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.top",
                  value: _vm.tooltip,
                  expression: "tooltip",
                  modifiers: { hover: true, top: true },
                },
              ],
              staticClass: "pinnedMessageAlert",
              attrs: { variant: _vm.variant, show: "" },
            },
            [
              _c("div", { staticClass: "icon" }, [_c("SvgInfoCircle")], 1),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }