var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "28",
        height: "24",
        viewBox: "0 0 28 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M23.3 1.163C21.5858 0.397319 19.6436 0.316608 17.8718 0.937288C16.0999 1.55797 14.6327 2.83301 13.771 4.50101C12.9091 2.83269 11.4415 1.55737 9.6693 0.936677C7.89704 0.315989 5.95446 0.396971 4.24 1.163C0.576004 2.863 -1.17599 7.20303 1.71701 12.323C3.77301 15.967 7.41701 18.712 13.771 23.635C20.124 18.712 23.771 15.966 25.821 12.323C28.715 7.20303 26.963 2.862 23.3 1.163Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }